import { WellSelector } from "@/components";
import { constants } from "@/configs";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Form, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";

import GeneratedWellConfigurationReport from "./GeneratedWellConfigurationReport";
import { useSelector } from "react-redux";

const WellConfigurationReport: FC = () => {
  const [form] = Form.useForm();
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [reportConfig, setReportConfig] = useState<any>(undefined);
  const [wells, setWells] = useState<any[]>([]);

  const wellIds: any[] = useWatch("wellIds", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const tempWellIds: any[] = wellIds;

    setReportConfig({
      wellIds: tempWellIds ?? [],
      wells:
        wells
          ?.filter((well) => wellIds?.includes(well?.id))
          ?.map((well) => well?.name)
          ?.join(", ") ?? [],
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <WellSelector form={form} propertyToSet={"wellIds"} label={"Wells"} placeholder="Leave blank to select all wells" style={{ margin: 0, marginBottom: 10 }} setWells={setWells} />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedWellConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default WellConfigurationReport;
