import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import { convertUnits, formatNumber } from "@/services/utils";
import { Card, Spin } from "antd";
import { CSSProperties, FC, memo, ReactNode, useMemo } from "react";
interface AnalyticsOverviewProps {
  style?: CSSProperties;
  permitType?: string;
  waterRightsAnalyticsOverview?: any[];
}

type Totals = {
  totalAuthorizedQuanity: number;
  totalIrrigationAcres: number;
  totalUsedWhereAuthQuantityApplicable: number;
  totalUsed: number;
};

const AnalyticsTotals: FC<AnalyticsOverviewProps> = ({ style, permitType, waterRightsAnalyticsOverview }) => {
  const renderItem = (label: string, value: string | ReactNode) => (
    <div style={{ width: "100%", display: "flex", flexDirection: "row", marginBottom: 5, fontSize: 16 }}>
      <div>{label}:</div>
      <div style={{ flex: 1, borderBottom: "1px dotted black", opacity: 0.4, marginBottom: 10, marginLeft: 20, marginRight: 20 }}></div>
      <div>{value}</div>
    </div>
  );

  const totals = useMemo(() => {
    if (waterRightsAnalyticsOverview) {
      console.log(waterRightsAnalyticsOverview);
      const totals: Totals = waterRightsAnalyticsOverview.reduce(
        (totals: Totals, x: any) => {
          const tempTotals = {
            totalAuthorizedQuanity: totals.totalAuthorizedQuanity + (x.quantityNotApplicable ? 0 : convertUnits(x.authorizedQuantity, x.authorizedQuantityUnits, "gallons")),
            totalIrrigationAcres: totals.totalIrrigationAcres + (x.irrigationAcresNotApplicable ? 0 : x.authorizedIrrigationAcres),
            totalUsedWhereAuthQuantityApplicable: totals.totalUsedWhereAuthQuantityApplicable + (x.quantityNotApplicable ? 0 : x.currentUsage),
            totalUsed: totals.totalUsed + x.currentUsage,
          };
          return tempTotals;
        },
        {
          totalAuthorizedQuanity: 0,
          totalIrrigationAcres: 0,
          totalUsedWhereAuthQuantityApplicable: 0,
          totalUsed: 0,
        }
      );

      return {
        authorizedQuanity: totals.totalAuthorizedQuanity ?? 0,
        irrigationAcres: totals.totalIrrigationAcres ?? 0,
        inchesPerAcre: convertUnits(totals.totalAuthorizedQuanity, "gallons", "acreinches") / (totals.totalIrrigationAcres === 0 ? 1 : totals.totalIrrigationAcres) ?? 0,
        totalUsed: totals.totalUsedWhereAuthQuantityApplicable ?? 0,
        totalUsedPercentage: (totals.totalUsedWhereAuthQuantityApplicable / (totals.totalAuthorizedQuanity === 0 ? 1 : totals.totalAuthorizedQuanity) ?? 0) * 100,
        totalSaved: totals.totalAuthorizedQuanity - totals.totalUsedWhereAuthQuantityApplicable ?? 0,
        totalSavedPercentage: ((totals.totalAuthorizedQuanity - totals.totalUsedWhereAuthQuantityApplicable) / (totals.totalAuthorizedQuanity === 0 ? 1 : totals.totalAuthorizedQuanity) ?? 0) * 100,
      };
    } else return null;
  }, [waterRightsAnalyticsOverview]);

  return (
    <Card bordered={false} className="removeBoxShadow" loading={!(permitType && totals)} styles={{ body: { padding: 20 } }}>
      {totals && (
        <>
          {permitType !== "stockwater" && (
            <>
              {renderItem("Total Authorized Quantity", <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={totals.authorizedQuanity} showUnitsLabel />)}
              {renderItem("Total Irrigation Acres", formatNumber(totals.irrigationAcres.toFixed(2)))}
              {renderItem("Average Inches / Acre", formatNumber(totals.inchesPerAcre.toFixed(2)))}
            </>
          )}
          {permitType === "stockwater" && (
            <>
              {renderItem("Head # / Day", formatNumber((0)?.toFixed(2)))}
              {renderItem("Gallon / Head / Day", formatNumber((0)?.toFixed(2)))}
              {renderItem("Total Acre Feet", formatNumber((0)?.toFixed(2)))}
              {renderItem("Total Gallons / Year", formatNumber((0)?.toFixed(2)))}
            </>
          )}
          {renderItem(
            "Water Used",
            <>
              <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={totals.totalUsed} showUnitsLabel /> - {formatNumber(totals.totalUsedPercentage?.toFixed(2))}%
            </>
          )}
          {renderItem(
            "Water Saved",
            <>
              <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={totals.totalSaved} showUnitsLabel /> - {formatNumber(totals.totalSavedPercentage?.toFixed(2))}%
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default memo(AnalyticsTotals);
