import { getRestrictions, syncRestrictionCalculations } from "@/apis/restriction.api";
import { LookupLabel } from "@/components";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import { constants } from "@/configs";
import { Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedRestrictionYearlyBreakdownReport.scss";
import useRestrictionLookups from "@/queries/useRestrictionLookups";

interface Props {
  reportConfig: {
    restrictionIds?: string[];
    restrictions?: string;
    generatedDate: string;
    year?: number;
  };
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const GeneratedRestrictionYearlyBreakdownReport: FC<Props> = (props) => {
  const { reportConfig } = props;

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const [loadingRestrictions, setLoadingRestrictions] = useState<boolean>(true);
  const [loadingCalculations, setLoadingCalculations] = useState<boolean>(true);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [restrictions, setRestrictions] = useState<any[]>([]);
  const [restrictionIds, setRestrictionIds] = useState<string[]>([]);
  const [calculations, setCalculations] = useState<any[]>([]);

  const [restrictionColumns, setRestrictionColumns] = useState<any[]>([]);

  const { restrictions: rLookup } = useRestrictionLookups();

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    if (reportConfig?.restrictionIds) {
      refreshRestrictions();
      refreshCalculations();
    }
    // eslint-disable-next-line
  }, [reportConfig]);

  useEffect(() => {
    if (reportConfig.restrictionIds !== undefined && reportConfig.restrictionIds.length > 0) {
      setRestrictionIds(reportConfig.restrictionIds);
    } else setRestrictionIds(rLookup?.map((restriction: any) => restriction?.id));
  }, [reportConfig, rLookup]);

  useEffect(() => {
    calculateRestrictionColumns();
    // eslint-disable-next-line
  }, [restrictions, calculations]);

  const refreshCalculations = async () => {
    setLoadingCalculations(true);
    const response = await syncRestrictionCalculations({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setCalculations(data.value);
      }
    }
    setLoadingCalculations(false);
  };

  const refreshRestrictions = async () => {
    setLoadingRestrictions(true);

    const response = await getRestrictions({ companyId: selectedCompanyId, year: reportConfig?.year });
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRestrictions(data.value);
      }
    }

    setLoadingRestrictions(false);
  };

  const determineValue = (id: string, type: string, postfix: any = undefined) => {
    const existingCalculation = calculations.find((calculation) => calculation.id === id);
    if (existingCalculation) {
      return existingCalculation[type];
    } else return undefined;
  };

  const calculateRestrictionColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Restriction Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any) => (
          <>
            <div>{val}</div>
            {!selectedCompanyId && (
              <div style={{ fontSize: 11 }}>
                (<CompanyLabel companyId={restrictions?.find((restriction: any) => restriction?.id === record?.id)?.companyId} />)
              </div>
            )}
          </>
        ),
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: (val: string, record: any) => {
          if (!record?.myfa) return <LookupLabel lookupType="restrictiontype" value={val} />;
          else
            return (
              <>
                <LookupLabel lookupType="restrictiontype" value={val} /> - MYFA
              </>
            );
        },
      },
      {
        title: "Start Date",
        key: "startDate",
        dataIndex: "startDate",
        render: (val: any, record: any) => dayjs(record.startDate).format(constants.dateFormat),
      },
      {
        title: "End Date",
        key: "endDate",
        dataIndex: "endDate",
        render: (val: any, record: any) => dayjs(record.endDate).format(constants.dateFormat),
      },
      {
        title: "Years",
        key: "years",
        dataIndex: "years",
        align: "right",
        render: (val: any, record: any) => val,
      },
      {
        title: "Remaining Years",
        key: "remainingYears",
        dataIndex: "remainingYears",
        align: "right",
        render: (val: any, record: any) => val,
      },
      {
        title: (
          <>
            Term Quantity
            <br />
            <small>(Acre Feet)</small>
          </>
        ),
        key: "termQuantity",
        dataIndex: "termQuantity",
        align: "right",
        width: 200,
        render: (val: any, record: any) => (
          <div>
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={determineValue(record.id, "termQuantity")} />
          </div>
        ),
      },
      {
        title: (
          <>
            Used Quantity
            <br />
            {/* {selectedCompany?.settings?.metric !== "acrefeet" && (
              <>
                <small>
                  (
                  <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
                </small>
                <br />
              </>
            )} */}
            <small>(Acre Feet)</small>
            <br />
            <small>(Inches / Acre)</small>
          </>
        ),
        key: "used",
        dataIndex: "used",
        align: "right",
        width: 200,
        render: (val: any, record: any) => (
          <div>
            {/* {selectedCompany?.settings?.metric !== "acrefeet" && (
              <>
                <UnitsConverter fromUnits="gallons" toUnits={selectedCompany?.settings?.metric} value={determineValue(record.id, "used")} />
                <br />
              </>
            )} */}
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={determineValue(record.id, "used")} /> <br />
            <UnitsConverter fromUnits="gallons" toUnits="acreinches" value={determineValue(record.id, "used")} devideBy={record.authorizedIrrigationAcres} />
          </div>
        ),
      },
      // {
      //     title: <>
      //         Used Quantity
      //         <br />
      //         <small>(Inches / Acre)</small>
      //     </>,
      //     key: "used",
      //     dataIndex: "used",
      //     align: 'right',
      //     width: 200,
      //     render: (val: any, record: any) => {
      //         return <div><UnitsConverter fromUnits='gallons' toUnits='acreinches' value={determineValue(record.id, 'used')} devideBy={record.authorizedIrrigationAcres} /></div>
      //     }
      // },
      // TODO: Confirm with Arno
      {
        title: (
          <>
            Current Year Usage
            <br />
            {/* {selectedCompany?.settings?.metric !== "acrefeet" && (
              <>
                <small>
                  (
                  <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
                </small>
                <br />
              </>
            )} */}
            <small>(Acre Feet)</small>
            <br />
            <small>(Inches / Acre)</small>
          </>
        ),
        key: "currentYearUsage",
        dataIndex: "currentYearUsage",
        align: "right",
        width: 200,
        render: (val: any, record: any) => (
          <div>
            {/* {selectedCompany?.settings?.metric !== "acrefeet" && (
              <>
                <UnitsConverter fromUnits="gallons" value={determineValue(record.id, "currentYearUsage")} />
                <br />{" "}
              </>
            )} */}
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={determineValue(record.id, "currentYearUsage")} /> <br />
            <UnitsConverter fromUnits="gallons" toUnits="inchesperacre" value={determineValue(record.id, "currentYearUsage")} devideBy={record.authorizedIrrigationAcres} />
          </div>
        ),
      },
      {
        title: "Yearly Remaining Average",
        key: "remainingYearlyAverage",
        dataIndex: "remainingYearlyAverage",
        align: "right",
        width: 200,
        render: (val: any, record: any) => (
          <div style={{ fontSize: "0.8em" }}>
            {/* {selectedCompany?.settings?.metric !== "acrefeet" && (
              <div>
                <UnitsConverter fromUnits="gallons" showUnitsLabel value={determineValue(record.id, "remainingYearlyAverage")} />
              </div>
            )} */}
            <div>
              <UnitsConverter fromUnits="gallons" toUnits="acrefeet" showUnitsLabel value={determineValue(record.id, "remainingYearlyAverage")} />
            </div>
            {determineValue(record.id, "inchesPerAcre") !== null && (
              <div>
                <UnitsConverter fromUnits="inchesperacre" toUnits="inchesperacre" value={determineValue(record.id, "inchesPerAcre")} postFix="Inches / Acre" />
              </div>
            )}
          </div>
        ),
      },
    ];

    setRestrictionColumns(tempColumns);
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedSummaryReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Restriction Yearly Breakdown Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          loading={loadingRestrictions && loadingCalculations}
        >
          {
            <Card title="Restrictions">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  loading={loadingRestrictions}
                  columns={restrictionColumns}
                  dataSource={restrictionIds ? calculations.filter((restriction) => restrictionIds?.includes(restriction.id)) : []}
                  size="small"
                  pagination={false}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Restrictions">
                  {reportConfig?.restrictionIds
                    ? restrictions
                        ?.filter((restriction) => reportConfig?.restrictionIds?.includes(restriction.id))
                        .map((restriction) => restriction.name)
                        .join(", ")
                    : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
};

export default GeneratedRestrictionYearlyBreakdownReport;
