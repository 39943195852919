import { Card, Empty } from "antd";
import { FC, useMemo } from "react";
import { Pie, PieChart } from "recharts";
import { ChartConfig, ChartContainer, ChartStyle, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { convertUnits } from "@/services/utils";
import { forEach, groupBy, set } from "lodash";
import LookupLabel from "@/components/LookupLabel/LookupLabel";

type CropAllocationByAcresProps = {
  waterRightsAnalyticsOverview?: any[];
};

const id = "pie-interactive-crop-allocation-acres";

const additionalOptions = [
  {
    value: "unallocated",
    label: "Unallocated",
  },
];

const CropAllocationByAcres: FC<CropAllocationByAcresProps> = ({ waterRightsAnalyticsOverview }) => {
  const chartData = useMemo(() => {
    const subTotals: { [key: string]: number } = {};

    const filteredList = waterRightsAnalyticsOverview?.filter((x: any) => x.permitType !== "stockwater" && !!x.irrigationAcresNotApplicable === false);

    forEach(groupBy(filteredList, "cropType"), (permitTypeData: any[], tempCropType: string) => {
      const cropType = tempCropType === "null" ? "unallocated" : tempCropType;
      console.log("tempCropType", tempCropType, cropType, permitTypeData);
      subTotals[cropType] = permitTypeData.reduce((acc, curr) => acc + convertUnits(curr.authorizedQuantity, curr.authorizedQuantityUnits, "gallons"), 0);
    });

    const total = Object.values(subTotals).reduce((acc, curr) => acc + curr, 0);

    const data: any[] = Object.keys(subTotals).map((cropType: string, index: number) => {
      return {
        label: cropType,
        value: Math.round((subTotals[cropType] / (total === 0 ? 1 : total)) * 100 * 100) / 100,
        fill: `hsl(var(--chart-${(index + 1).toString()}))`,
      };
    });

    return {
      data,
      total,
    };
  }, [waterRightsAnalyticsOverview]);

  const chartConfig = useMemo(() => {
    const config: ChartConfig = {};

    chartData.data?.forEach((chartObj: any, index: number) => {
      const obj = { label: chartObj?.permitType, color: `hsl(var(--chart-${(index + 1).toString()}))` };
      const lowerCaseCompanyName = chartObj?.permitType;
      set(config, lowerCaseCompanyName, obj);
    });

    return config;
  }, [chartData]);

  return (
    <Card
      bordered={false}
      className="removeBoxShadow"
      styles={{
        body: {
          padding: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <div style={{ flex: 1, fontWeight: "bold", fontSize: 18 }}>Crop Allocation by Acres</div>
      {chartData.total === 0 ? (
        <Empty description="No Usage Data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <ChartStyle id={id} config={chartConfig} />
          <ChartContainer id={id} config={chartConfig} className="mx-auto aspect-square w-full max-w-[250px]" style={{ overflow: "visible" }}>
            <PieChart style={{ overflow: "visible" }}>
              <ChartTooltip
                cursor={false}
                content={
                  <ChartTooltipContent hideLabel postFix="%" lookupComponent={(label: any) => <LookupLabel value={label} lookupType="cropType" dontShowCode additionalOptions={additionalOptions} />} />
                }
              />
              <Pie
                data={chartData.data}
                nameKey="label"
                dataKey="value"
                labelLine={false}
                label={({ payload, ...props }) => {
                  return (
                    <text cx={props.cx} cy={props.cy} x={props.x} y={props.y} textAnchor={props.textAnchor} dominantBaseline={props.dominantBaseline}>
                      <LookupLabel lookupType="cropType" value={payload.label} dontShowCode additionalOptions={additionalOptions} /> - {payload.value.toString()}%
                    </text>
                  );
                }}
                style={{ overflow: "visible" }}
              />
            </PieChart>
          </ChartContainer>
        </>
      )}
    </Card>
  );
};

export default CropAllocationByAcres;
