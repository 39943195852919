import { getRestrictions, syncRestrictionCalculations } from "@/apis/restriction.api";
import { LookupLabel } from "@/components";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import { constants } from "@/configs";
import useWaterRightLookups from "@/queries/useWaterRightLookups";
import useWellLookups from "@/queries/useWellLookups";
import { Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedSummaryReport.scss";
import useRestrictionLookups from "@/queries/useRestrictionLookups";

interface Props {
  reportConfig: {
    restrictionIds?: string[];
    restrictions?: string;
    generatedDate: string;
  };
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

export default function GeneratedRestrictionConfigurationReport(props: Props) {
  const { reportConfig } = props;

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { waterRights } = useWaterRightLookups();
  const { wells } = useWellLookups();
  const { restrictions: restrictionLookups } = useRestrictionLookups();

  const [loadingRestrictions, setLoadingRestrictions] = useState<boolean>(true);
  const [loadingRestrictionCalculations, setLoadingRestrictionCalculations] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [tempRestrictions, setRestrictions] = useState<any[]>([]);
  const [restrictionCalculations, setRestrictionCalculations] = useState<any[]>([]);

  const [restrictionColumns, setRestrictionColumns] = useState<any[]>([]);
  const [restrictionIds, setRestrictionIds] = useState<string[]>([]);

  const restrictions: any[] = useMemo(() => {
    const data = tempRestrictions.map((restriction: any) => {
      const waterRightsForRestriction = waterRights.filter((waterRight: any) => restriction.waterRightIds.includes(waterRight.id)).map((waterRight: any) => waterRight.fileNumber);
      const waterRightIds = restriction.waterRightIds;
      const wellsForRestriction = wells.filter((well: any) => waterRightIds.includes(well.waterRightId)).map((well: any) => well.name);

      return {
        ...restriction,
        waterRights: waterRightsForRestriction?.length > 0 ? waterRightsForRestriction : ["-"],
        wells: wellsForRestriction?.length > 0 ? wellsForRestriction : ["-"],
      };
    });
    return data;
  }, [waterRights, wells, tempRestrictions]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    if (reportConfig?.restrictionIds !== undefined && reportConfig?.restrictionIds?.length > 0 && restrictionLookups?.length > 0) {
      setRestrictionIds(reportConfig.restrictionIds);
    } else {
      setRestrictionIds(restrictionLookups?.map((restriction: any) => restriction?.id));
    }
    // eslint-disable-next-line
  }, [reportConfig, restrictionLookups]);

  useEffect(() => {
    if (restrictionIds.length > 0) {
      refreshRestrictions();
      refreshRestrictionCalculations();
    }
  }, [restrictionIds]);

  useEffect(() => {
    calculateRestrictionColumns();
    // eslint-disable-next-line
  }, [restrictions, restrictionCalculations]);

  const refreshRestrictionCalculations = async () => {
    setLoadingRestrictionCalculations(true);
    const response = await syncRestrictionCalculations({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRestrictionCalculations(data.value);
      }
    }
    setLoadingRestrictionCalculations(false);
  };

  const refreshRestrictions = async () => {
    setLoadingRestrictions(true);

    const response = await getRestrictions({ companyId: selectedCompanyId, restrictionIds: restrictionIds });
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRestrictions(data.value);
      }
    }
    setLoadingRestrictions(false);
  };

  const determineRestrictionValue = (id: string, type: string, postfix: any = undefined) => {
    const existingCalculation = restrictionCalculations.find((calculation) => calculation.id === id);
    if (existingCalculation) {
      return existingCalculation[type];
    } else return undefined;
  };

  const calculateRestrictionColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Restriction Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any) => (
          <>
            <div>{val}</div>
          </>
        ),
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: (val: string, record: any) => {
          if (!record?.myfa) return <LookupLabel lookupType="restrictiontype" value={val} />;
          else
            return (
              <>
                <LookupLabel lookupType="restrictiontype" value={val} /> - MYFA
              </>
            );
        },
      },
      {
        title: "Start Date",
        key: "startDate",
        dataIndex: "startDate",
        render: (val: any, record: any) => dayjs(record.startDate).format(constants.dateFormat),
      },
      {
        title: "End Date",
        key: "endDate",
        dataIndex: "endDate",
        render: (val: any, record: any) => dayjs(record.endDate).format(constants.dateFormat),
      },
      {
        title: "Years",
        render: (val: any, record: any) => determineRestrictionValue(record?.id, "years"),
      },
      {
        title: (
          <>
            Term Quantity <small>(Acre Feet)</small>
          </>
        ),
        key: "termQuantity",
        dataIndex: "termQuantity",
        align: "right",
        width: 300,
        render: (val: any, record: any) => val,
      },
    ];

    setRestrictionColumns(tempColumns);
  };

  const renderRestrictionExpandData = (record: any) => {
    return (
      <div style={{ padding: 5 }}>
        {record?.waterRights?.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                fontSize: 12,
                width: 100,
                fontWeight: "bold",
                padding: 0,
              }}
            >
              Water Rights:{" "}
            </div>
            <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.waterRights?.map((x: any) => x).join(", ")}</div>
          </div>
        )}
        {record?.wells?.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                fontSize: 12,
                width: 100,
                fontWeight: "bold",
                padding: 0,
              }}
            >
              Wells / Meters:{" "}
            </div>
            <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.wells?.map((x: any) => x).join(", ")}</div>
          </div>
        )}
        {!selectedCompanyId && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                fontSize: 12,
                width: 100,
                fontWeight: "bold",
                padding: 0,
              }}
            >
              Company:{" "}
            </div>
            <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>
              <CompanyLabel companyId={record?.companyId} />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedRestrictionSummaryReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Restriction Configuration Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          // title={`Summary Report on ${reportConfig.generatedDate}`}
          loading={loadingRestrictions}
        >
          {
            <Card title="Restrictions">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  loading={loadingRestrictions}
                  columns={restrictionColumns}
                  dataSource={restrictions ?? []}
                  size="small"
                  pagination={false}
                  expandable={{
                    showExpandColumn: false,
                    defaultExpandAllRows: true,
                    expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
                    expandedRowRender: (record: any) => renderRestrictionExpandData(record),
                  }}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Restrictions">{reportConfig.restrictions === "" ? "-" : reportConfig.restrictions}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
}
