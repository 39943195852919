import { ChartConfig, ChartContainer, ChartStyle, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { Card, Empty } from "antd";
import { forEach, groupBy, set } from "lodash";
import { FC, useMemo } from "react";
import { Pie, PieChart } from "recharts";
import LookupLabel from "@/components/LookupLabel/LookupLabel";
import { convertUnits } from "@/services/utils";

type WaterAllocationByWaterSourceProps = {
  waterRightsAnalyticsOverview?: any[];
};

const id = "pie-interactive-water-allocation-permit-type";

const WaterAllocationByWaterSource: FC<WaterAllocationByWaterSourceProps> = ({ waterRightsAnalyticsOverview }) => {
  const chartData = useMemo(() => {
    const lookups: any[] = [];
    const subTotals: { [key: string]: number } = {};

    const filteredList: any = []; //waterRightsAnalyticsOverview?.filter((x: any) => (!!x.quantityNotApplicable) === false);

    forEach(groupBy(filteredList, "permitType"), (permitTypeData: any[], permitType: string) => {
      lookups.push(permitType);
      subTotals[permitType] = permitTypeData.reduce((acc, curr) => acc + convertUnits(curr.authorizedQuantity, curr.authorizedQuantityUnits, "gallons"), 0);
    });

    const total = Object.values(subTotals).reduce((acc, curr) => acc + curr, 0);

    const data: any[] = Object.keys(subTotals).map((permitType: string, index: number) => {
      return {
        label: permitType,
        value: Math.round((subTotals[permitType] / (total === 0 ? 1 : total)) * 100 * 100) / 100,
        fill: `hsl(var(--chart-${(index + 1).toString()}))`,
      };
    });

    return {
      lookups,
      data,
      total,
    };
  }, [waterRightsAnalyticsOverview]);

  const chartConfig = useMemo(() => {
    const config: ChartConfig = {};

    chartData.data?.forEach((chartObj: any, index: number) => {
      const obj = { label: chartObj?.permitType, color: `hsl(var(--chart-${(index + 1).toString()}))` };
      const lowerCaseCompanyName = chartObj?.permitType;
      set(config, lowerCaseCompanyName, obj);
    });

    return config;
  }, [chartData]);

  return (
    <Card
      bordered={false}
      className="removeBoxShadow"
      styles={{
        body: {
          padding: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <div style={{ flex: 1, fontWeight: "bold", fontSize: 18 }}>Allocation by Water Source</div>
      {chartData.total === 0 ? (
        <Empty description="No Usage Data" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <>
          <ChartStyle id={id} config={chartConfig} />
          <ChartContainer id={id} config={chartConfig} className="mx-auto aspect-square w-full max-w-[250px]" style={{ overflow: "visible" }}>
            <PieChart style={{ overflow: "visible" }}>
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent hideLabel postFix="%" lookupComponent={(label: any) => <LookupLabel value={label} lookupType="permitType" dontShowCode />} />}
              />
              <Pie
                data={chartData.data}
                nameKey="label"
                dataKey="value"
                labelLine={false}
                label={({ payload, ...props }) => {
                  console.log(props);
                  return (
                    <text cx={props.cx} cy={props.cy} x={props.x} y={props.y} textAnchor={props.textAnchor} dominantBaseline={props.dominantBaseline}>
                      <LookupLabel onlyShowCode lookupType="permitType" value={payload.label} dontShowCode /> - {payload.value.toString()}%
                    </text>
                  );
                }}
                style={{ overflow: "visible" }}
              />
            </PieChart>
          </ChartContainer>
        </>
      )}
    </Card>
  );
};

export default WaterAllocationByWaterSource;
