import { EventsTable } from "@/components";
import useRoleBasedFeature, { RoleBasedFeature } from "@/services/useRoleBasedFeature";
import { FC, useState } from "react";
import { get } from "@/services/request";
import { Button } from "antd";
import { constants } from "@/configs";

const seedEvents = [
  {
    name: "1. IDSV",
    request: () => get(`${constants.oidcConfig.authority}/api/user/seedevents`),
  },
  {
    name: "2. Companies Added",
    request: () => get(`company/seedevents`),
  },
  {
    name: "3. Fields Added",
    request: () => get(`field/seedevents`),
  },
  {
    name: "4.1. WaterRights Added",
    request: () => get(`waterright/seedevents?step=1`),
  },
  {
    name: "4.2. WaterRight Notes Added",
    request: () => get(`waterright/seedevents?step=2`),
  },
  {
    name: "4.3. WaterRight Adjustments Added",
    request: () => get(`waterright/seedevents?step=3`),
  },
  {
    name: "5.1. Wells Added",
    request: () => get(`well/seedevents?step=1`),
  },
  {
    name: "5.2. Well Tokens Assigned",
    request: () => get(`well/seedevents?step=2`),
  },
  {
    name: "5.3. Well Tokens Replaced",
    request: () => get(`well/seedevents?step=3`),
  },
  {
    name: "5.4. Well Readings Added",
    request: () => get(`well/seedevents?step=4`),
  },
  {
    name: "5.5. Well Notes Added",
    request: () => get(`well/seedevents?step=5`),
  },
  {
    name: "6. Groups Added",
    request: () => get(`farm/seedevents`),
  },
  {
    name: "7.1. Stocks Added",
    request: () => get(`farm/stock/seedevents?step=1`),
  },
  {
    name: "7.2. Occupancy Added",
    request: () => get(`farm/stock/seedevents?step=2`),
  },
  {
    name: "8. Restrictions Added",
    request: () => get(`restriction/seedevents`),
  },
];

const AdminEvents: FC = () => {
  const hasAccessToFeature = useRoleBasedFeature();
  const [actioned, setActioned] = useState<string[]>([]);

  const handleRequest = (name: string, request: any) => {
    request()
      .then((res: any) => {
        console.log("Seeding " + name + " => ", res);
        setActioned([...actioned, name]);
      })
      .catch((err: any) => {
        console.error("Error seeding " + name + " => ", err);
      });
  };

  return (
    <>
      {hasAccessToFeature?.[RoleBasedFeature.SeedEvents] &&
        seedEvents.map(({ name, request }) => (
          <Button key={name} disabled={actioned.includes(name)} onClick={() => handleRequest(name, request)}>
            {name}
          </Button>
        ))}
      <EventsTable showCard eventTypes={[]} />
    </>
  );
};

export default AdminEvents;
