import { getRestrictionReportLookup } from "@/apis/restriction.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

type RestrictionReportLookupProps = {
  isActive: boolean | null;
};

const useRestrictionReportLookupQuery = ({ isActive }: RestrictionReportLookupProps) => {
  const { profile } = useSelector((state: any) => state.user);

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const { data, isFetching, isFetched, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: [
      CacheKeys.restrictions,
      CacheKeys.lookup,
      {
        profileId: profile?.id,
        companyId: selectedCompanyId,
        isActive: isActive,
      },
    ],
    queryFn: async ({ pageParam }) => {
      const response = await getRestrictionReportLookup({
        companyId: selectedCompanyId,
        page: pageParam,
        pageSize: 50,
        isActive: isActive,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch restrictions lookup");
    },
    ...defaultQueryConfig,
    placeholderData: keepPreviousData,
    enabled: !!profile?.id,
    gcTime: Infinity,
    staleTime: Infinity,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.pagination?.hasNextPage) return undefined;
      return lastPageParam + 1;
    },
  });

  const invalidateRestrictionLookup = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.restrictions, CacheKeys.lookup],
    });
  }, [queryClient]);

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage && !isFetching) fetchNextPage();
  }, [hasNextPage, isFetchingNextPage]);

  const restrictionLookup = useMemo(() => data?.pages?.map((page) => page.list).flat() ?? [], [data]);
  const totalItemCount = useMemo(() => data?.pages?.[(data?.pages?.length ?? 0) - 1]?.pagination?.totalItemCount ?? 0, [data]);

  return {
    restrictionLookup,
    isLoading,
    fetchNextPage,
    invalidateRestrictionLookup,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    isFetched,
    totalItemCount,
  };
};

export default useRestrictionReportLookupQuery;
