import useWellReportLookupQuery from "@/queries/useWellReportLookupQuery";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { Button, Divider, Form, FormInstance, Select, Space, Switch, Tag } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StatusTag from "../StatusTag/StatusTag";
import useCompanyLookups from "@/queries/useCompanyLookups";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";

type WellSelectorProps = {
  placeholder?: string;
  form: FormInstance<any> | undefined;
  style?: React.CSSProperties;
  propertyToSet: string;
  label: string;
  setWells: any;
};

const WellSelector: FC<WellSelectorProps> = ({ placeholder, form, style, propertyToSet, label, setWells }) => {
  const [wellDownRef, wellDownEntry] = useIntersectionObserver();

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { companyConfigs } = useComapnyConfigLookup();

  const [isActive, setIsActive] = useState<boolean | null>(true);

  const { wellLookup, invalidateWellLookup, fetchNextPage, hasNextPage, isFetched, isFetching, isFetchingNextPage, isLoading, totalItemCount } = useWellReportLookupQuery({ isActive: isActive });

  useEffect(() => {
    if (wellDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [wellDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (wellLookup.length > 0) {
      setWells(wellLookup);
    }
    // eslint-disable-next-line
  }, [wellLookup]);

  const handleReset = () => {
    form!.setFieldValue(propertyToSet, []);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {wellLookup?.find((well) => well?.id === value)?.name}
      </Tag>
    );
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(null);
    else setIsActive(true);
  };

  return (
    <Form.Item label={label} name={propertyToSet} style={style}>
      <Select
        allowClear
        mode={"multiple"}
        loading={isLoading || isFetching}
        tagRender={tagRender}
        placeholder={placeholder}
        showSearch
        filterOption={(input, option) => {
          const searchVal = input?.toLowerCase();
          let list: any[] = [];
          wellLookup?.forEach((well) => {
            let match: boolean = false;

            if (well?.name?.toLowerCase()?.includes(searchVal)) match = true;

            if (match) list.push(well?.id);
          });
          return list.includes(option?.value) ?? [];
        }}
        optionFilterProp="value"
        maxTagCount={3}
        disabled={isLoading}
        options={wellLookup?.map((well: any) => {
          return {
            value: well.id,
            label: well.name,
          };
        })}
        optionRender={(option, { index }) => {
          if (option.value === "loading") return null;
          const well = wellLookup?.find((well) => well.id === option?.value);
          if (!well) return null;

          return (
            <div key={well.id}>
              {index === (wellLookup?.length ?? 0) - 1 ? <span ref={wellDownRef} /> : null}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  // paddingBottom: 5,
                }}
              >
                {well?.name} <StatusTag status={well?.status} style={{}} />
              </div>
              {!selectedCompanyId && (
                <div style={{ fontWeight: "normal", fontSize: 11, paddingTop: 0, paddingBottom: 5 }}>({companyConfigs?.find((company: any) => company?.id === well?.companyId)?.name})</div>
              )}
            </div>
          );
        }}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 8px 4px" }}>
              {/* <Button
                onClick={() => {
                  form!.setFieldValue(
                    propertyToSet,
                    wellLookup?.filter((item: any) => item !== undefined).map((well) => well.id)
                  );
                  setWellNames(
                    wellLookup
                      ?.filter((well) => well !== undefined)
                      ?.map((well) => well?.name)
                      ?.join(", ")
                  );
                }}
              >
                Select All
              </Button> */}
              <Button
                danger
                onClick={() => {
                  handleReset();
                }}
              >
                Clear List
              </Button>
              <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
            </Space>
          </>
        )}
      ></Select>
    </Form.Item>
  );
};

export default WellSelector;
