import useCompanyLookups from "@/queries/useCompanyLookups";
import { Button, Divider, Form, FormInstance, Select, Space, Switch, Tag } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect, useState } from "react";
import StatusTag from "../StatusTag/StatusTag";

type CompanySelectorProps = {
  placeholder?: string;
  form: FormInstance<any> | undefined;
  style?: React.CSSProperties;
  propertyToSet: string;
  label: string;
  setCompanies: any;
  singleSelect?: boolean;
};

const CompanyReportSelector: FC<CompanySelectorProps> = ({ placeholder, form, style, propertyToSet, label, setCompanies, singleSelect = false }) => {
  // const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [isActive, setIsActive] = useState<boolean | undefined>(true);

  const { companies, invalidateCompanies, isLoading } = useCompanyLookups(undefined, undefined, isActive);

  // useEffect(() => {
  //     handleReset();
  //     // eslint-disable-next-line
  // }, [selectedCompanyId]);

  useEffect(() => {
    console.log("companies", companies);
    if (companies?.length > 0) {
      setCompanies(companies);
    }
    // eslint-disable-next-line
  }, [companies]);

  const handleReset = () => {
    form!.setFieldValue(propertyToSet, []);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {companies?.find((company: any) => company?.id === value)?.name}
      </Tag>
    );
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(undefined);
    else setIsActive(true);
  };

  return (
    <Form.Item label={label} name={propertyToSet} style={style}>
      <Select
        allowClear
        mode={singleSelect ? undefined : "multiple"}
        loading={isLoading}
        tagRender={tagRender}
        placeholder={placeholder}
        showSearch
        filterOption={(input, option) => {
          const searchVal = input?.toLowerCase();
          let list: any[] = [];
          companies?.forEach((company: any) => {
            let match: boolean = false;

            if (company?.name?.toLowerCase()?.includes(searchVal)) match = true;

            if (match) list.push(company?.id);
          });
          return list.includes(option?.value) ?? [];
        }}
        optionFilterProp="value"
        maxTagCount={3}
        disabled={isLoading}
        options={companies?.map((company: any) => {
          return {
            value: company.id,
            label: company.name,
          };
        })}
        optionRender={(option, { index }) => {
          if (option.value === "loading") return null;
          const company = companies?.find((company: any) => company.id === option?.value);
          if (!company) return null;

          return (
            <div key={company.id}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  // paddingBottom: 5,
                }}
              >
                {company?.name} <StatusTag status={company?.active} style={{}} />
              </div>
            </div>
          );
        }}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 8px 4px" }}>
              <Button
                danger
                onClick={() => {
                  handleReset();
                }}
              >
                Clear List
              </Button>
              <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
            </Space>
          </>
        )}
      ></Select>
    </Form.Item>
  );
};

export default CompanyReportSelector;
