import { FieldSelector, GroupingSelector, RestrictionSelector, StockSelector, WaterRightReportSelector, WellSelector } from "@/components";
import { constants } from "@/configs";
import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Select, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedAllConfigurationReport from "./GeneratedAllConfigurationReport";
import useGroupingLookupQuery from "@/queries/useGroupingLookupQuery";
import useFieldReportLookup from "@/queries/useFieldReportLookup";
import useWellReportLookupQuery from "@/queries/useWellReportLookupQuery";
import useWaterRightReportLookupQuery from "@/queries/useWaterRightReportLookupQuery";
import useRestrictionReportLookupQuery from "@/queries/useRestrictionReportLookupQuery";
import useStockLookupQuery from "@/queries/useStockLookupQuery";

const AllConfigurationReport: FC = () => {
  const { lookups } = useSelector((state: any) => state.lookups);
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [stock, setStocks] = useState<any[]>([]);
  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [restrictions, setRestrictions] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);
  const [fields, setFields] = useState<any[]>([]);
  const [groupings, setGroupings] = useState<any[]>([]);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const wellIds: any[] = useWatch("wellIds", form);
  const stockIds: any[] = useWatch("stockIds", form);
  const groupingIds: any[] = useWatch("groupingIds", form);
  const fieldIds: any[] = useWatch("fieldIds", form);
  const waterRightIds: any[] = useWatch("waterRightIds", form);
  const restrictionIds: any[] = useWatch("restrictionIds", form);
  const permitTypes: any[] = useWatch("permitTypes", form);
  const priorityTypes: any[] = useWatch("priorityTypes", form);

  const { groupingLookup } = useGroupingLookupQuery();
  const { fieldLookup } = useFieldReportLookup({ isActive: false });
  const { wellLookup } = useWellReportLookupQuery({ isActive: false });
  const { waterRightLookup } = useWaterRightReportLookupQuery({ isActive: false });
  const { restrictionLookup } = useRestrictionReportLookupQuery({ isActive: false });
  const { stockLookup } = useStockLookupQuery({ searchString: undefined });

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const handleGenerateReport = () => {
    setGeneratingReport(true);
    const reportConfig: any = {
      groupingIds: undefined,
      groupings: "-",
      fieldIds: undefined,
      fields: "-",
      wellIds: undefined,
      wells: "-",
      waterRightIds: undefined,
      waterRights: "-",
      restrictionIds: undefined,
      restrictions: "-",
      stockIds: undefined,
      stock: "-",
      permitTypes: "-",
      priorityTypes: "-",
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      hasAdditionalFilters: false,
    };

    //Nothing selected
    if (
      (groupingIds?.length ?? 0) === 0 &&
      (fieldIds?.length ?? 0) === 0 &&
      (wellIds?.length ?? 0) === 0 &&
      (waterRightIds?.length ?? 0) === 0 &&
      (restrictionIds?.length ?? 0) === 0 &&
      (stockIds?.length ?? 0) === 0 &&
      (permitTypes?.length ?? 0) === 0 &&
      (priorityTypes?.length ?? 0) === 0
    ) {
      setReportConfig(reportConfig);
      return;
    }

    reportConfig.hasAdditionalFilters = true;

    const groupingsMap = new Set();
    const fieldsMap = new Set();
    const wellsMap = new Set();
    const waterRightsMap = new Set();
    const restrictionsMap = new Set();
    const stockMap = new Set();

    if (groupingIds?.length > 0) {
      groupingIds.forEach((groupingId) => {
        groupingsMap.add(groupingId);
        const grouping = groupingLookup.find((grouping) => grouping.id === groupingId);
        grouping?.waterRights?.forEach((waterRightId: string) => {
          waterRightsMap.add(waterRightId);
          const wells = wellLookup.filter((well) => well.waterRightId === waterRightId);
          wells.forEach((well) => {
            wellsMap.add(well.id);
            fieldsMap.add(well.fieldId);
          });

          const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(waterRightId));
          stock?.forEach((stock) => stockMap.add(stock.id));

          const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(waterRightId));
          restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
        });
      });
    }

    if (fieldIds?.length > 0) {
      fieldIds.forEach((fieldId) => {
        fieldsMap.add(fieldId);
        const wells = wellLookup.filter((well) => well.fieldId === fieldId);
        wells.forEach((well) => {
          wellsMap.add(well.id);
          waterRightsMap.add(well.waterRightId);
          const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(well.waterRightId));
          stock?.forEach((stock) => stockMap.add(stock.id));

          const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(well.waterRightId));
          restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
        });
      });
    }

    if (wellIds?.length > 0) {
      wellIds.forEach((wellId) => {
        wellsMap.add(wellId);
        const well = wellLookup.find((well) => well.id === wellId);
        fieldsMap.add(well?.fieldId);
        waterRightsMap.add(well?.waterRightId);

        const grouping = groupingLookup.filter((grouping) => grouping.waterRights?.includes(well?.waterRightId));
        grouping?.forEach((grouping) => groupingsMap.add(grouping.id));

        const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(well?.waterRightId));
        stock?.forEach((stock) => stockMap.add(stock.id));

        const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(well?.waterRightId));
        restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
      });
    }

    if (waterRightIds?.length > 0) {
      waterRightIds.forEach((waterRightId) => {
        waterRightsMap.add(waterRightId);
        const wells = wellLookup.filter((well) => well.waterRightId === waterRightId);
        wells.forEach((well) => {
          wellsMap.add(well.id);
          fieldsMap.add(well.fieldId);
        });

        const grouping = groupingLookup.filter((grouping) => grouping.waterRights?.includes(waterRightId));
        grouping?.forEach((grouping) => groupingsMap.add(grouping.id));

        const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(waterRightId));
        stock?.forEach((stock) => stockMap.add(stock.id));

        const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(waterRightId));
        restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
      });
    }

    if (restrictionIds?.length > 0) {
      restrictionIds.forEach((restrictionId) => {
        restrictionsMap.add(restrictionId);
        const restriction = restrictionLookup.find((restriction) => restriction.id === restrictionId);
        restriction?.waterRightIds?.forEach((waterRightId: string) => {
          waterRightsMap.add(waterRightId);
          const wells = wellLookup.filter((well) => well.waterRightId === waterRightId);
          wells.forEach((well) => {
            wellsMap.add(well.id);
            fieldsMap.add(well.fieldId);
          });

          const grouping = groupingLookup.filter((grouping) => grouping.waterRights?.includes(waterRightId));
          grouping?.forEach((grouping) => groupingsMap.add(grouping.id));

          const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(waterRightId));
          stock?.forEach((stock) => stockMap.add(stock.id));
        });
      });
    }

    if (stockIds?.length > 0) {
      stockIds.forEach((stockId) => {
        stockMap.add(stockId);
        const stock = stockLookup.find((stock) => stock.id === stockId);
        stock?.waterRightIds?.forEach((waterRightId: string) => {
          waterRightsMap.add(waterRightId);
          const wells = wellLookup.filter((well) => well.waterRightId === waterRightId);
          wells.forEach((well) => {
            wellsMap.add(well.id);
            fieldsMap.add(well.fieldId);
          });

          const grouping = groupingLookup.filter((grouping) => grouping.waterRights?.includes(waterRightId));
          grouping?.forEach((grouping) => groupingsMap.add(grouping.id));

          const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(waterRightId));
          restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
        });
      });
    }

    if (permitTypes?.length > 0) {
      waterRightLookup.forEach((waterRight) => {
        if (permitTypes.includes(waterRight.permitType)) {
          waterRightsMap.add(waterRight.id);
          const wells = wellLookup.filter((well) => well.waterRightId === waterRight.id);
          wells.forEach((well) => {
            wellsMap.add(well.id);
            fieldsMap.add(well.fieldId);
          });

          const grouping = groupingLookup.filter((grouping) => grouping.waterRights?.includes(waterRight.id));
          grouping?.forEach((grouping) => groupingsMap.add(grouping.id));

          const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(waterRight.id));
          stock?.forEach((stock) => stockMap.add(stock.id));

          const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(waterRight.id));
          restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
        }
      });
    }

    if (priorityTypes?.length > 0) {
      waterRightLookup.forEach((waterRight) => {
        if (priorityTypes.includes(waterRight.priorityType)) {
          waterRightsMap.add(waterRight.id);
          const wells = wellLookup.filter((well) => well.waterRightId === waterRight.id);
          wells.forEach((well) => {
            wellsMap.add(well.id);
            fieldsMap.add(well.fieldId);
          });

          const grouping = groupingLookup.filter((grouping) => grouping.waterRights?.includes(waterRight.id));
          grouping?.forEach((grouping) => groupingsMap.add(grouping.id));

          const stock = stockLookup.filter((stock) => stock.waterRightIds?.includes(waterRight.id));
          stock?.forEach((stock) => stockMap.add(stock.id));

          const restriction = restrictionLookup.filter((restriction) => restriction.waterRightIds?.includes(waterRight.id));
          restriction?.forEach((restriction) => restrictionsMap.add(restriction.id));
        }
      });
    }

    reportConfig.groupingIds = Array.from(groupingsMap);
    reportConfig.groupings = groupingLookup
      .filter((grouping) => reportConfig.groupingIds?.includes(grouping.id))
      .map((grouping) => grouping.name)
      .join(", ");
    reportConfig.fieldIds = Array.from(fieldsMap);
    reportConfig.fields = fieldLookup
      .filter((field) => reportConfig.fieldIds?.includes(field.id))
      .map((field) => field.name)
      .join(", ");
    reportConfig.wellIds = Array.from(wellsMap);
    reportConfig.wells = wellLookup
      .filter((well) => reportConfig.wellIds?.includes(well.id))
      .map((well) => well.name)
      .join(", ");
    reportConfig.waterRightIds = Array.from(waterRightsMap);
    reportConfig.waterRights = waterRightLookup
      .filter((waterRight) => reportConfig.waterRightIds?.includes(waterRight.id))
      .map((waterRight) => waterRight.fileNumber)
      .join(", ");
    reportConfig.restrictionIds = Array.from(restrictionsMap);
    reportConfig.restrictions = restrictionLookup
      .filter((restriction) => reportConfig.restrictionIds?.includes(restriction.id))
      .map((restriction) => restriction.name)
      .join(", ");
    reportConfig.stockIds = Array.from(stockMap);
    reportConfig.stock = stockLookup
      .filter((stock) => reportConfig.stockIds?.includes(stock.id))
      .map((stock) => stock.name)
      .join(", ");

    const permitTypeLookups = lookups?.find((lookup: any) => lookup.map === "permittype");
    const priorityTypeLookups = lookups?.find((lookup: any) => lookup.map === "prioritytype");

    reportConfig.permitTypes = permitTypeLookups.options
      .filter((option: any) => permitTypes?.includes(option.value))
      .map((option: any) => option.name)
      .join(", ");
    reportConfig.priorityTypes = priorityTypeLookups.options
      .filter((option: any) => priorityTypes?.includes(option.value))
      .map((option: any) => option.name)
      .join(", ");

    console.log("Reportconfig=> ", reportConfig);

    setReportConfig(reportConfig);

    // // Water Right Ids
    // const restrictionWRIds = (restrictions ?? []).filter((restriction: any) => (restrictionIds ?? []).includes(restriction.id)).map((restriction: any) => restriction.waterRightIds ?? []);
    // const tempWrIdsForStock = (stock ?? []).filter((stock) => (stockIds ?? []).includes(stock.id)).map((stock) => stock.waterRightIds ?? []);
    // const tempWRsForPermitTypes = waterRights.filter((waterRight) => permitTypes && permitTypes.includes(waterRight.permitType)).map((waterRight) => waterRight.id);
    // const tempWRsForPriorityTypes = waterRights.filter((waterRight) => priorityTypes && priorityTypes.includes(waterRight.priorityType)).map((waterRight) => waterRight.id);
    // const tempWRIds = [...(waterRightIds ?? []), ...restrictionWRIds.flat(), ...tempWrIdsForStock.flat()];

    // tempWRsForPermitTypes.forEach((tempWr) => {
    //   const wr = tempWRIds.find((waterRight) => waterRight === tempWr);
    //   if (!wr) tempWRIds.push(tempWr);
    // });

    // tempWRsForPriorityTypes.forEach((tempWr) => {
    //   const wr = tempWRIds.find((waterRight) => waterRight === tempWr);
    //   if (!wr) tempWRIds.push(tempWr);
    // });

    // // Well Ids
    // const wellsForWaterRight = wells.filter((well) => tempWRIds.includes(well.waterRightId)).map((well) => well.id);
    // const tempWells = [...(wellIds ?? []), ...wellsForWaterRight.flat()];
    // const waterRightsFromWell = wells.filter((well) => tempWells.includes(well.id)).map((well) => well.waterRightId);
    // waterRightsFromWell.forEach((wr) => {
    //   const wrToFind = tempWRIds.find((waterRight) => waterRight === wr);
    //   if (!wrToFind) tempWRIds.push(wr);
    // });

    // // Field ids
    // const fieldIdsFromWell = wells.filter((well) => tempWells.includes(well.id)).map((well) => well.fieldId);
    // const fieldsFromWell = fields.filter((field) => fieldIdsFromWell.includes(field.id)).map((field) => field.id);
    // const tempFields = [...(fieldIds ?? []), ...fieldsFromWell.flat()];
    // // WELL
    // const wellsForField = wells.filter((well) => tempFields.includes(well.fieldId)).map((well) => well.id);
    // wellsForField.forEach((well) => {
    //   const wellToFind = tempWells.find((tempWell) => tempWell === well);
    //   if (!wellToFind) tempWells.push(well);
    // });
    // // WR
    // const waterRightWellForField = wells.filter((well) => tempFields.includes(well.fieldId)).map((well) => well.waterRightId);
    // waterRightWellForField.forEach((wr) => {
    //   const wrToFind = tempWRIds.find((waterRight) => waterRight === wr);
    //   if (!wrToFind) tempWRIds.push(wr);
    // });

    // // Restriction Ids
    // const tempRestrictionIds = (restrictions ?? []).filter((restriction: any) => (restriction.waterRightIds ?? []).some((x: any) => tempWRIds.includes(x))).map((restriction: any) => restriction.id);

    // // Grouping Ids
    // const groupingsFromWaterRights = (groupings ?? []).filter((grouping: any) => (grouping.waterRights ?? []).some((x: any) => waterRights.includes(x))).map((grouping: any) => grouping.id);
    // const tempGroupings = [...(groupingIds ?? []), ...groupingsFromWaterRights.flat()];
    // // WR
    // const groupingWaterRights = groupings.filter((grouping) => grouping?.waterRights?.length > 0 && tempGroupings.includes(grouping.id)).map((grouping) => grouping.waterRights);
    // groupingWaterRights.forEach((groupingWrs) => {
    //   groupingWrs.forEach((wr: any) => {
    //     const wrToFind = tempWRIds.find((waterRight) => waterRight === wr);
    //     if (!wrToFind) tempWRIds.push(wr);
    //   });
    // });
    // // Well
    // const wellsForGrouping = wells.filter((well) => tempWRIds.includes(well.waterRightId)).map((well) => well.id);
    // wellsForGrouping.forEach((well) => {
    //   const wellToFind = tempWells.find((tempWell) => tempWell === well);
    //   if (!wellToFind) tempWells.push(well);
    // });
    // // Field
    // const fieldsForWellGrouping = wells.filter((well) => tempWells.includes(well.id)).map((well) => well.fieldId);
    // fieldsForWellGrouping.forEach((fieldForGrouping) => {
    //   const fieldToFind = tempFields.find((field) => field === fieldForGrouping);
    //   if (!fieldToFind) tempFields.push(fieldForGrouping);
    // });
    // // Restrictions
    // const tempGroupingRestrictionIds = (restrictions ?? [])
    //   .filter((restriction: any) => (restriction.waterRightIds ?? []).some((x: any) => tempWRIds.includes(x)))
    //   .map((restriction: any) => restriction.id);
    // tempGroupingRestrictionIds.forEach((restriction) => {
    //   const restrictionToFind = tempRestrictionIds.find((tempRestriction) => tempRestriction === restriction);
    //   if (!restrictionToFind) tempRestrictionIds.push(restriction);
    // });
    // // Stock
    // // Grouping Ids
    // const stockFromWaterRights = (stock ?? []).filter((stock: any) => (stock.waterRightIds ?? []).some((x: any) => waterRights.includes(x))).map((stock: any) => stock.id);
    // const tempStock = [...(stockIds ?? []), ...stockFromWaterRights.flat()];

    // setReportConfig({
    //   selectedGroupingNames: groupings
    //     .filter((grouping) => groupingIds?.includes(grouping.id))
    //     .map((grouping) => grouping.name)
    //     .join(", "),
    //   groupingIds: tempGroupings,
    //   groupings: groupings
    //     .filter((grouping: any) => tempGroupings?.includes(grouping.id))
    //     .map((grouping: any) => grouping.name)
    //     .join(", "),
    //   selectedFieldNames: fields
    //     .filter((field) => fieldIds?.includes(field.id))
    //     .map((field) => field.name)
    //     .join(", "),
    //   fieldIds: tempFields,
    //   fields: fields
    //     .filter((field: any) => tempFields?.includes(field.id))
    //     .map((field: any) => field.name)
    //     .join(", "),
    //   selectedWellNames: wells
    //     .filter((well) => wellIds?.includes(well.id))
    //     .map((well) => well.name)
    //     .join(", "),
    //   wellIds: tempWells,
    //   wells: wells
    //     .filter((well: any) => tempWells?.includes(well.id))
    //     .map((well: any) => well.name)
    //     .join(", "),
    //   selectedWaterRightNames: waterRights
    //     .filter((waterRight) => waterRightIds?.includes(waterRight.id))
    //     .map((waterRight) => waterRight.fileNumber)
    //     .join(", "),
    //   waterRightIds: tempWRIds,
    //   waterRights: waterRights
    //     .filter((waterRight: any) => tempWRIds?.includes(waterRight.id))
    //     .map((waterRight: any) => waterRight.fileNumber)
    //     .join(", "),
    //   selectedRestrictionNames: restrictions
    //     .filter((restriction) => restrictionIds?.includes(restriction.id))
    //     .map((restriction) => restriction.name)
    //     .join(", "),
    //   restrictionIds: tempRestrictionIds,
    //   restrictions: restrictions
    //     .filter((restriction: any) => tempRestrictionIds?.includes(restriction.id))
    //     .map((restriction: any) => restriction.name)
    //     .join(", "),
    //   stockIds: stockIds,
    //   stock: stock
    //     .filter((stock: any) => tempStock?.includes(stock.id))
    //     .map((stock: any) => stock.name)
    //     .join(", "),
    //   permitTypes: permitTypeLookups?.options
    //     ?.filter((option: any) => permitTypes?.includes(option.value))
    //     ?.sort((a: any, b: any) =>
    //       a?.name?.localeCompare(b?.name, "en", {
    //         numeric: true,
    //         sensitivity: "base",
    //       })
    //     )
    //     ?.map((option: any) => option.name)
    //     .join(", "),
    //   priorityTypes: priorityTypeLookups?.options
    //     ?.filter((option: any) => priorityTypes?.includes(option.value))
    //     ?.sort((a: any, b: any) =>
    //       a?.name?.localeCompare(b?.name, "en", {
    //         numeric: true,
    //         sensitivity: "base",
    //       })
    //     )
    //     ?.map((option: any) => option.name)
    //     .join(", "),
    //   generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
    // });

    setReportConfig(reportConfig);
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <GroupingSelector
            form={form}
            propertyToSet={"groupingIds"}
            label={"Groupings"}
            setGroupings={setGroupings}
            style={{ margin: 0, marginBottom: 10 }}
            placeholder="Leave blank to select all groupings"
          />
          <FieldSelector form={form} propertyToSet={"fieldIds"} label={"Fields"} placeholder="Leave blank to select all fields" style={{ margin: 0, marginBottom: 10 }} setFields={setFields} />
          <WellSelector form={form} propertyToSet={"wellIds"} label={"Wells"} placeholder="Leave blank to select all wells" style={{ margin: 0, marginBottom: 10 }} setWells={setWells} />
          <WaterRightReportSelector
            form={form}
            propertyToSet={"waterRightIds"}
            label={"Water Rights"}
            setWaterRights={setWaterRights}
            placeholder="Leave blank to select all water rights"
            style={{ margin: 0, marginBottom: 10 }}
          />
          <RestrictionSelector
            form={form}
            propertyToSet={"restrictionIds"}
            label={"Restrictions"}
            placeholder="Leave blank to select all restrictions"
            style={{ margin: 0, marginBottom: 10 }}
            setRestrictions={setRestrictions}
          />
          <StockSelector form={form} propertyToSet={"stockIds"} label={"Stock"} placeholder="Leave blank to select all stocks" style={{ margin: 0, marginBottom: 0 }} setStocks={setStocks} />
          <Form.Item label="Permit Type" name="permitTypes" style={{ margin: 0, paddingTop: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={generatingReport}
              placeholder="Leave blank to select all permit types"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "permitTypes",
                          lookups.find((lookup: any) => lookup.map === "permittype").options.map((lookup: any) => lookup.value)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("permitTypes", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {lookups
                .find((lookup: any) => lookup.map === "permittype")
                .options.map((lookup: any) => {
                  return (
                    <Select.Option value={lookup.value} key={lookup.id} label={lookup.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {lookup.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item label="Priority Type" name="priorityTypes" style={{ margin: 0, paddingTop: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={generatingReport}
              placeholder="Leave blank to select all priority types"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "priorityTypes",
                          lookups.find((lookup: any) => lookup.map === "prioritytype").options.map((lookup: any) => lookup.value)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("priorityTypes", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {lookups
                .find((lookup: any) => lookup.map === "prioritytype")
                .options.map((lookup: any) => {
                  return (
                    <Select.Option value={lookup.value} key={lookup.id} label={lookup.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {lookup.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedAllConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default AllConfigurationReport;
