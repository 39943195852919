import useListBillingUsersInfiniteQuery from "@/queries/useListBillingUsersInfiniteQuery";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, Empty, Input, message, Space, Switch, Table, Tag } from "antd";
import { CSSProperties, FC, useEffect, useState } from "react";
import AdminBillingUsersExpand from "./AdminBillingUserExpand";
import { useIntersectionObserver, useWindowSize } from "@uidotdev/usehooks";
import { updateNotBillable, clearPendingBillableItems, generateFinanceDocument, generateAnnualSubscription } from "@/apis/aggregator.api";
import NotBillableButton from "./components/NotBillableButton";
import ClearPendingBillableButton from "./components/ClearPendingBillableButton";
import GenerateInvoiceButton from "./components/GenerateInvoiceButton";
import GenerateAnnualSubscriptionButton from "./components/GenerateAnnualSubscriptionButton";

type AdminBillingUsersProps = {
  styles?: {
    tableStyle?: CSSProperties;
  };
};

const AdminBillingUsers: FC<AdminBillingUsersProps> = ({ styles }) => {
  const [usersDownRef, usersDownEntry] = useIntersectionObserver();

  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");
  const [hasPendingBillableItems, setHasPendingBillableItems] = useState<boolean>(false);

  const [columns, setColumns] = useState<any[]>([]);

  const {
    billingUsers,
    isFetching,
    fetchNextPage,
    invalidateBillingUsers,
    optimisticBillableStatusUpdate,
    optimisticGenerateInvoiceUpdate,
    optimisticClearItemsUpdate,
    hasNextPage,
    isLoading,
    totalItemCount,
    currentItemCount,
    isFetchingNextPage,
  } = useListBillingUsersInfiniteQuery({
    searchString: searchString,
    hasPendingBillableItems: hasPendingBillableItems,
  });

  useEffect(() => {
    if (usersDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [usersDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    calculateBillingColumns();
  }, [billingUsers]);

  const handleRefresh = () => {
    invalidateBillingUsers();
  };

  const handleOnChange = (value: boolean) => {
    setHasPendingBillableItems(value);
  };

  const updateBillableStatus = async (id: string, billable: boolean, entityType: string, entityName: string, companyName: string | undefined, onCompleted: () => void) => {
    try {
      const response = await updateNotBillable({ id, billable, entityType, entityName, companyName });
      if (response.ok) {
        optimisticBillableStatusUpdate(id, billable);
      } else {
        message.error("Failed to update billable status");
      }
    } catch (error) {
      message.error("Failed to update billable status");
    }
    onCompleted();
  };

  const handleClearPendingBillableItems = async (userId: string, billableItemIds: string[], onCompleted: () => void) => {
    try {
      const response = await clearPendingBillableItems({ userId, billableItemIds });
      if (response.ok) {
        optimisticClearItemsUpdate(userId, billableItemIds);
      } else {
        message.error("Failed to clear pending billable items");
      }
    } catch (error) {
      message.error("Failed to clear pending billable items");
    }
    onCompleted();
  };

  const handleGenerateFinanceDocument = async (user: any, onCompleted: () => void) => {
    try {
      const response = await generateFinanceDocument({ user });
      if (response.ok) {
        const billableItemIds = user.pendingBillableItems.filter((x: any) => x.isBillable).map((x: any) => x.id);
        optimisticGenerateInvoiceUpdate(user.id, billableItemIds);
      } else {
        message.error("Failed to generate invoice");
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to generate invoice");
    }
    onCompleted();
  };

  const handleGenerateAnnualSubscription = async (userIds: string[], year: number, onCompleted: () => void) => {
    try {
      const response = await generateAnnualSubscription({ userIds, year });
      if (response.ok) {
        message.success(`Annual subscription${userIds.length > 1 ? "s" : ""} queued successfully. This may take a minute or two to complete.`);
      } else {
        message.error(`Failed to queue annual subscription${userIds.length > 1 ? "s" : ""}`);
      }
    } catch (error) {
      console.log(error);
      message.error(`Failed to queue annual subscription${userIds.length > 1 ? "s" : ""}`);
    }
    onCompleted();
  };

  const calculateBillingColumns = () => {
    let tempColumns = [
      {
        title: "Name",
        key: "userFirstName",
        dataIndex: "userFirstName",
        render: (val: any, record: any, index: any) => (
          <>
            {record?.user?.firstName} {record?.user?.lastName}
            {index === currentItemCount - 1 ? <span ref={usersDownRef} /> : null}
          </>
        ),
      },
      {
        title: "Email",
        key: "userEmail",
        dataIndex: "userEmail",
        render: (val: any, record: any) => record?.user?.email?.toLowerCase(),
      },
      {
        title: "Billable",
        key: "billable",
        width: 70,
        align: "center",
        render: (val: any, record: any) => (!record.notBillableEntities?.includes(val.id) ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>),
      },
      {
        title: "Wells",
        key: "pendingWells",
        dataIndex: "pendingWells",
        width: 100,
        align: "center",
        render: (val: any, record: any) => record?.pendingWells,
      },
      {
        title: "Assigned Tokens",
        key: "pendingAssignedTokens",
        dataIndex: "pendingAssignedTokens",
        width: 140,
        align: "center",
        render: (val: any, record: any) => record?.pendingAssignedTokens,
      },
      {
        title: "Replaced Tokens",
        key: "pendingReplacedTokens",
        dataIndex: "pendingReplacedTokens",
        width: 140,
        align: "center",
        render: (val: any, record: any) => record?.pendingReplacedTokens,
      },
      {
        title: "Actions",
        width: 180,
        render: (val: any, record: any) => (
          <>
            <NotBillableButton
              id={val.id}
              isBillable={!record.notBillableEntities?.includes(val.id)}
              name={`${record.user?.firstName} ${record.user?.lastName}`}
              type="User"
              onUpdateBillableStatus={updateBillableStatus}
            />
            {" | "}
            <ClearPendingBillableButton
              name={`${record.user?.firstName} ${record.user?.lastName}`}
              userId={record.id}
              billableItemIds={record?.pendingBillableItems?.filter((x: any) => x.isBillable).map((item: any) => item.id) ?? []}
              onClearPendingBillableItems={handleClearPendingBillableItems}
            />
            {" | "}
            <GenerateInvoiceButton
              name={`${record.user?.firstName} ${record.user?.lastName}`}
              email={record.user?.email}
              pendingWells={record?.pendingWells}
              pendingAssignedTokens={record?.pendingAssignedTokens}
              pendingReplacedTokens={record?.pendingReplacedTokens}
              user={record}
              onGenerateInvoice={handleGenerateFinanceDocument}
            />
            {" | "}
            <GenerateAnnualSubscriptionButton
              name={`${record.user?.firstName} ${record.user?.lastName}`}
              email={record.user?.email}
              userIds={[record.id]}
              onGenerateAnnualSubscription={handleGenerateAnnualSubscription}
            />
          </>
        ),
      },
    ];

    setColumns(tempColumns);
  };

  const windowSize = useWindowSize();

  const renderBillableUsersTable = () => {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Space direction="horizontal">
            <Space style={{ paddingBottom: 10 }}>
              <Input.Search
                disabled={isLoading}
                placeholder="Search billable users"
                onSearch={(val) => {
                  setSearchString(val);
                }}
                onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                value={searchStringValue}
                style={{ width: 400 }}
                allowClear
              />
              <Button disabled={isLoading} icon={<ReloadOutlined />} onClick={handleRefresh}>
                Refresh
              </Button>
              <Switch onChange={handleOnChange} checkedChildren="Have Pending Billable Items" unCheckedChildren="All Billable Users" />
              {/* <Button disabled={isLoading} icon={<ReloadOutlined />} onClick={handleRefresh}>
                Generate Subscriptions
              </Button> */}
            </Space>
          </Space>
          <span style={{ fontSize: "16px", paddingRight: 25, fontWeight: 600 }}>Total: {totalItemCount ?? "-"}</span>
        </div>
        <Table
          virtual
          className="customSelectScrollBar"
          rowKey={(row: any) => row.id}
          loading={isLoading || isFetchingNextPage}
          columns={columns}
          dataSource={billingUsers ?? []}
          size="small"
          expandable={{
            columnWidth: 55,
            expandedRowRender: (record: any) => (
              <AdminBillingUsersExpand
                record={record}
                isBillable={!record?.notBillableEntities?.includes(record.id)}
                refresh={handleRefresh}
                optimisticBillableStatusUpdate={optimisticBillableStatusUpdate}
                optimisticClearItemsUpdate={optimisticClearItemsUpdate}
              />
            ),
          }}
          pagination={false}
          scroll={{ y: windowSize?.height ? windowSize.height - 400 : 600 }}
          style={{ height: 400, width: "99%" }}
          locale={{
            emptyText: <Empty description="No Users" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
          }}
        />
      </>
    );
  };

  return renderBillableUsersTable();
};

export default AdminBillingUsers;
