import { LastReadingFrequency } from "@/components";
import RadialGauge from "@/components/Gauges/RadialGauge";
import { routes } from "@/configs";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useListWellDashboardQuery from "@/queries/useListWellDashboardQuery";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { ReloadOutlined, SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { Button, Card, Col, Empty, Input, Row, Select, Skeleton, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";

const gridStyle: React.CSSProperties = {
  textAlign: "center",
  cursor: "pointer",
  maxWidth: 330,
};

const tempSelectValues: any[] = [
  {
    label: "Alphabetical",
    value: "alphabetical",
  },
  {
    label: "Remaining Days",
    value: "remainingDays",
  },
  {
    label: "Percentage Remaining",
    value: "percentageRemaining",
  },
];

export default function GaugesDashboard() {
  const dispatch = useAppDispatch();
  const { navigate } = useCustomNavigate();

  const [searchString, setSearchString] = useState<string | undefined>("");
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");

  const [selectValue, setSelectValue] = useState<string>("alphabetical");
  const [sortOrder, setSortOrder] = useState<string>("ASC");

  const [downref, downentry] = useIntersectionObserver();

  const { companyConfigs } = useComapnyConfigLookup();

  const {
    dashboardWells: wells,
    fetchNextPage,
    invalidateDashboardWells,
    hasNextPage,
    isLoading,
    totalItemCount,
    isFetchingNextPage,
  } = useListWellDashboardQuery({
    searchString: searchString,
    sortOrder: {
      Name: selectValue === "alphabetical" ? sortOrder : undefined,
      RemainingDays: selectValue === "remainingDays" ? sortOrder : undefined,
      PercentageRemaining: selectValue === "percentageRemaining" ? sortOrder : undefined,
    },
    pageIdentifier: "gaugesDashboard",
  });

  useEffect(() => {
    setSortOrder("ASC");
  }, [selectValue]);

  const handleRefresh = () => {
    setSearchString(undefined);
    setSearchStringValue(undefined);
    invalidateDashboardWells();
  };

  const handleOnClick = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `well/${record.id}/view`,
      })
    );
    navigate(routes.wellView, { id: record.id });
  };

  useEffect(() => {
    if (downentry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [downentry?.isIntersecting, hasNextPage]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title={
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <span>Remaining Usage</span>
                <Space direction="horizontal">
                  <Space>
                    <Input.Search
                      disabled={isLoading}
                      placeholder="Search wells"
                      onSearch={(val) => {
                        setSearchString(val);
                      }}
                      onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                      value={searchStringValue}
                      style={{ width: 300, fontWeight: "normal" }}
                      allowClear
                    />
                    <Select
                      style={{ width: 200 }}
                      placeholder="Select an option"
                      optionFilterProp="label"
                      defaultValue={"alphabetical"}
                      onSelect={setSelectValue}
                      suffixIcon={
                        sortOrder === "ASC" ? (
                          <Tooltip title="Sorted Ascending">
                            <SortAscendingOutlined onClick={() => setSortOrder("DESC")} />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Sorted descending">
                            <SortDescendingOutlined onClick={() => setSortOrder("ASC")} />
                          </Tooltip>
                        )
                      }
                    >
                      {tempSelectValues.map((option: any) => {
                        return (
                          <Select.Option value={option.value} label={option.label} key={option.value}>
                            {option.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    <Button icon={<ReloadOutlined />} disabled={isLoading} onClick={handleRefresh}>
                      Refresh
                    </Button>
                  </Space>
                </Space>
                <span>Total: {totalItemCount ?? "-"}</span>
              </div>
            }
            bordered={false}
            loading={isLoading}
          >
            {!isLoading && wells?.length > 0 ? (
              wells.map((well: any, index, pages) => {
                const companyConfig = companyConfigs?.find((c: any) => c?.id === well?.companyId);
                const meterReadingFrequency = well.meterReadingFrequency ? well.meterReadingFrequency : companyConfig?.settings?.meterReadingFrequency;
                const meterReadingFrequencyDay = well.meterReadingFrequency ? well.meterReadingFrequencyDay : companyConfig?.settings?.meterReadingFrequencyDay;
                const meterReadingFrequencyDayOfWeek = well.meterReadingFrequency ? well.meterReadingFrequencyDayOfWeek : companyConfig?.settings?.meterReadingFrequencyDayOfWeek;
                const timezone = companyConfig?.settings?.timezone;

                return (
                  <Card.Grid style={gridStyle} onClick={() => handleOnClick(well)} key={well.id}>
                    <RadialGauge
                      header={well.name}
                      companyId={well.companyId}
                      availableQuantity={well.availableQuantity}
                      usedQuantity={well.currentUsage}
                      remainingDays={well.remainingDays ?? "N/A"}
                      borderless
                      style={{ paddingBottom: 0 }}
                    />
                    <div style={{ opacity: 0.7, fontSize: 12, paddingTop: 10, display: "flex", flexDirection: "row", alignItems: "center", gap: 10, justifyContent: "flex-end" }}>
                      Last Reading Date:{" "}
                      <LastReadingFrequency
                        date={well.lastReadingDate}
                        type={"well"}
                        meterReadingFrequency={meterReadingFrequency}
                        meterReadingFrequencyDay={meterReadingFrequencyDay}
                        meterReadingFrequencyDayOfWeek={meterReadingFrequencyDayOfWeek}
                        timezone={timezone}
                        placeholder="No Readings"
                      />
                    </div>
                    {index === pages.length - 1 ? <div ref={downref} /> : null}
                  </Card.Grid>
                );
              })
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Wells" />
            )}
            {isFetchingNextPage && (
              <Card.Grid>
                <Skeleton active />
              </Card.Grid>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}
