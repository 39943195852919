import { constants } from "@/configs";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import dayjs from "dayjs";
import { FC, useMemo } from "react";
import weekOfYear from "dayjs/plugin/weekOfYear";
import timezone from "dayjs/plugin/timezone";
import dayOfYear from "dayjs/plugin/dayOfYear";

dayjs.extend(weekOfYear);
dayjs.extend(timezone);
dayjs.extend(dayOfYear);

interface Props {
  type: string;
  date: any;
  meterReadingFrequency: string;
  meterReadingFrequencyDay?: number;
  meterReadingFrequencyDayOfWeek?: number;
  isReport?: boolean;
  includeNewLine?: boolean;
  placeholder?: string;
  timezone?: string;
  overrides?: { frequency?: string; day?: number; dayOfWeek?: number }[];
}

const LastReadingFrequency: FC<Props> = (props) => {
  const { type, date, meterReadingFrequency, isReport = false, includeNewLine, placeholder = "-", meterReadingFrequencyDay, meterReadingFrequencyDayOfWeek, timezone, overrides } = props;

  const renderDateTime = () => {
    if (includeNewLine)
      return (
        <div style={{ display: "flex", flexDirection: "column", gap: 2, justifyContent: "flex-start", alignItems: "flex-start" }}>
          <div>{dayjs(date).format(constants.dateFormat)}</div>
          <div>{dayjs(date).format(constants.timeFormatWithSeconds)}</div>
        </div>
      );
    return <span>{dayjs(date).format(constants.dateTimeFormat)}</span>;
  };

  const dueDate = useMemo(() => {
    const startOfDay = dayjs
      .utc()
      .tz(timezone ?? "utc")
      .startOf("day");
    let dueDate = startOfDay;

    switch (meterReadingFrequency) {
      case "weekly":
        dueDate = startOfDay.day(meterReadingFrequencyDayOfWeek ?? 0);
        break;
      case "bi-weekly":
        const dayDueThisWeek = startOfDay.day(meterReadingFrequencyDayOfWeek ?? 0);
        const weekOfYear = dayDueThisWeek.week();
        dueDate = weekOfYear % 2 === 1 ? dayDueThisWeek : dayDueThisWeek.subtract(7, "days");
        break;
      case "monthly":
        dueDate = startOfDay.date(meterReadingFrequencyDay ?? 1);
        if (dueDate.month() > dayjs().month()) dueDate = dueDate.startOf("month").add(-1, "day");
        break;
    }

    overrides?.forEach((override) => {
      let tempDueDate = startOfDay;
      switch (override.frequency) {
        case "weekly":
          tempDueDate = startOfDay.day(override.dayOfWeek ?? 0);
          break;
        case "bi-weekly":
          const dayDueThisWeek = startOfDay.day(override.dayOfWeek ?? 0);
          const weekOfYear = dayDueThisWeek.week();
          tempDueDate = weekOfYear % 2 === 1 ? dayDueThisWeek : dayDueThisWeek.subtract(7, "days");
          break;
        case "monthly":
          tempDueDate = startOfDay.date(override.day ?? 1);
          if (tempDueDate.month() > dayjs().month()) tempDueDate = tempDueDate.startOf("month").add(-1, "day");
          break;
      }
      if (tempDueDate < dueDate) dueDate = tempDueDate;
    });

    return dueDate;
  }, [timezone, overrides, meterReadingFrequency, meterReadingFrequencyDayOfWeek, meterReadingFrequencyDay]);

  const lastReadingDate = useMemo(
    () =>
      dayjs(date)
        .utc()
        .tz(timezone ?? "utc"),
    [timezone, date]
  );

  if (!date) return <>{placeholder}</>;
  if (isReport) return renderDateTime();

  const now = dayjs.utc().tz(timezone ?? "utc");
  const isDue = dueDate <= now && lastReadingDate < dueDate;

  return (
    <>
      {meterReadingFrequency === "none" ? (
        renderDateTime()
      ) : isDue ? (
        <Popover content={`This ${type}  is due for collection`}>
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <span style={{ color: isDue ? "red" : "black" }}>{renderDateTime()}</span>
            <InfoCircleOutlined style={{ color: isDue ? "red" : "black" }} />
          </div>
        </Popover>
      ) : (
        renderDateTime()
      )}
    </>
  );
};

export default LastReadingFrequency;
