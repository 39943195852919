import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getRestrictionsLookup } from "@/apis/restriction.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CacheKeys from "@/types/CacheKeys";

export default function useRestrictionLookups(restrictionId?: string) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();
  const {
    data: restrictions,
    isFetching,
    isRefetching,
    isFetched,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: [
      CacheKeys.restrictions,
      CacheKeys.lookup,
      {
        profileId: profile?.id,
      },
    ],
    queryFn: async () => {
      const response = await getRestrictionsLookup({});

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch restrictions");
    },
    gcTime: Infinity,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    placeholderData: (currentData) => currentData || [],
  });

  const invalidateRestrictions = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [CacheKeys.restrictionsLookup] });
  }, [queryClient]);

  const [restriction, setRestriction] = useState<any>(null);

  useEffect(() => {
    if (isFetched && !isError && !isFetching && !isRefetching && isSuccess && restrictionId && restrictions) {
      const tempRestriction = restrictions.find((r: any) => r.id === restrictionId);
      if (tempRestriction) {
        if (!isEqual(restriction, tempRestriction)) setRestriction(tempRestriction);
      } else {
        invalidateRestrictions();
      }
    }
  }, [isError, isFetched, isFetching, isRefetching, restrictionId, restrictions, invalidateRestrictions, isSuccess, restriction]);

  return { restriction, restrictions, invalidateRestrictions };
}
