import { get, post, put } from "@/services/request";

// export const getFields = (companyId: string, searchString: any) => {
//     return get(`field/list?companyId=${companyId}&${searchString ? '?searchString=' + searchString : ''}`)
// }

export const getFields = (request: { companyId?: string; searchString?: string; fieldIds?: string[] }) => {
  return post(`field/list`, request);
};

// export const getFieldsLookup = (request: {
//     fieldIds?: string[]
// }) => {
//     return post(`field/lookup`, request);
// }

export const getField = (id: any) => {
  return get(`field/${id}`);
};

export const addField = (request: any) => {
  return post("field", request);
};

export const updateField = (id: any, request: any) => {
  return put(`field/update/${id}`, request);
};

export const countFields = (companyId?: string) => {
  const queryParams = companyId ? `?companyId=${companyId}` : "";

  return get(`field/count` + queryParams);
};

export const getFieldsFromId = (request: any) => {
  return post(`field/fromids/list`, request);
};

export const getFieldName = (id: any) => {
  return get(`field/${id}/name`);
};

export const getFieldAuditLogs = (id: string) => {
  return get(`field/auditlog/${id}/list`);
};

export const getPagedFields = (request: any) => {
  return post(`field/paged/list`, request);
};

export const getFieldsLookup = (request: { fieldIds?: string[]; companyId?: string; isActive?: boolean }) => {
  return post(`field/lookup`, request);
};

export const getFieldReportLookup = (request: { companyId: string; page: number; pageSize: number; isActive: boolean | null }) => {
  return post(`field/report/lookup`, request);
};
