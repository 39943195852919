import { FormInstance, Select, Spin } from "antd";
import { NamePath } from "antd/es/form/interface";
import { getLookups } from "@/apis/lookups.api";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@/stores";
import { saveLookups } from "@/stores/lookups.store";
import { orderBy } from "lodash";

const applyConfigSelectorCallback = (config: any, selector: string, applyConfigCallback?: (partialConfig: any) => void) => {
  const partialConfig = config?.[selector];
  if (partialConfig && applyConfigCallback) applyConfigCallback(partialConfig);
};
interface Props {
  propertyToSet: NamePath;
  lookupType: string;
  placeholder: string;
  formRef?: React.RefObject<FormInstance<any>> | undefined;
  form?: FormInstance<any> | undefined;
  value?: any;
  style?: React.CSSProperties;
  locked?: boolean;
  constantValue?: any;
  setRef?: any;
  onBlur?: any;
  setDefault?: boolean;
  defaultOverride?: any;
  configSelector?: string;
  onApplyConfig?: (partialConfig: any) => void;
  multiple?: boolean;
  additionalOptions?: {
    value: any;
    label: string;
  }[];
  hideCode?: boolean;
  orderByOverride?: string[];

  [key: string]: any;
}

const LookupSelector: FC<Props> = (props) => {
  const {
    propertyToSet,
    lookupType,
    placeholder,
    formRef,
    form,
    value,
    style,
    locked,
    constantValue,
    setRef,
    onBlur,
    setDefault = false,
    defaultOverride,
    configSelector,
    onApplyConfig,
    multiple,
    hideCode = false,
    orderByOverride,
    ...additionalProps
  } = props;

  const dispatch = useAppDispatch();

  const { lookups } = useSelector((state: any) => state.lookups);

  const [defaultValue, setDefaultValue] = useState<any>({ isSet: false });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (lookups?.length === 0) refreshLookupsList();
    else setLoading(false);
  }, []);

  useEffect(() => {
    if (constantValue) {
      (form ?? formRef?.current)?.setFieldValue(propertyToSet, constantValue);
      setDefaultValue({ value: constantValue, isSet: true });
    } else if (setDefault && defaultOverride) {
      (form ?? formRef?.current)?.setFieldValue(propertyToSet, defaultOverride);
      setDefaultValue({ value: defaultOverride, isSet: true });
    } else if (lookups?.length > 0) {
      const lookup = lookups.find((lookup: any) => lookup.map === lookupType.toLowerCase());
      if (setDefault && lookup?.default !== undefined && lookup?.default !== null) {
        var option = lookup?.options?.find((option: any) => option.id === lookup.default);
        if (option) {
          if ((form ?? formRef?.current)?.getFieldValue(propertyToSet) === undefined) (form ?? formRef?.current)?.setFieldValue(propertyToSet, option.value);
          setDefaultValue({ value: option.value, isSet: true });
        }
      } else {
        setDefaultValue({ isSet: true });
      }
    }
  }, [constantValue, defaultOverride, lookups]);

  const refreshLookupsList = async () => {
    setLoading(true);
    const response = await getLookups();
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        dispatch(saveLookups(data.value));
      }
    }
    setLoading(false);
  };

  const renderOptions = (tempLookups: any[]) => {
    let lookup = tempLookups.find((lookup: any) => lookup.map === lookupType.toLowerCase());
    return lookup ? (
      (
        additionalProps?.additionalOptions?.map((option: any) => (
          <Select.Option value={option.value} key={option.value} label={option.label}>
            {option.label}
          </Select.Option>
        )) ?? []
      ).concat(
        orderBy(lookup.options, orderByOverride ?? (lookup.showCode && !hideCode ? ["code", "name"] : ["name"])).map((option: any) => (
          <Select.Option
            style={{ display: option.hidden ? "none" : undefined }}
            value={option.value}
            key={option.value}
            label={lookup.showCode && !hideCode ? `${option.code} - ${option.name}` : option.name}
          >
            {lookup.showCode && !hideCode ? `${option.code} - ${option.name}` : option.name}
          </Select.Option>
        ))
      )
    ) : (
      <></>
    );
  };

  useEffect(() => {
    if (value) {
      const config = lookups?.find((lookup: any) => lookup?.map === lookupType.toLowerCase())?.options?.find((option: any) => option.value === value)?.config;
      if (config && configSelector && onApplyConfig) applyConfigSelectorCallback(config, configSelector + "Config", onApplyConfig);
    }
  }, [value, lookups]);

  return loading ? (
    <Spin />
  ) : (
    defaultValue.isSet && (
      <Select
        onSelect={(val) => {
          (form ?? formRef?.current)?.setFieldValue(propertyToSet, val);
          const config = lookups?.find((lookup: any) => lookup?.map === lookupType.toLowerCase())?.options?.find((option: any) => option.value === val)?.config;
          if (config && configSelector) applyConfigSelectorCallback(config, configSelector + "ConfigFormFields", (form ?? formRef?.current)?.setFieldsValue);
        }}
        loading={loading}
        placeholder={placeholder}
        showSearch
        optionFilterProp="label"
        value={value ?? null}
        style={style}
        defaultValue={constantValue ?? defaultValue}
        showArrow={!locked}
        disabled={locked}
        ref={setRef ? (ref) => setRef(ref) : undefined}
        onBlur={onBlur}
        mode={multiple ? "multiple" : undefined}
        {...additionalProps}
      >
        {renderOptions(lookups)}
      </Select>
    )
  );
};

export default LookupSelector;
