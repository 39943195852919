import { Button, Card, Form } from "antd";
import { generateSummaryReport } from "@/apis/waterright.api";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { openReportFromGeneratedFile } from "@/services/utils";
import { useWatch } from "antd/es/form/Form";
import { CompanyReportSelector } from "@/components";

const ExportWaterRightInformationReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [generatingSummaryReport, setGeneratingSummaryReport] = useState<boolean>(false);
  const [companies, setCompanies] = useState<any[]>([]);

  const companyIds: any[] = useWatch("companyIds", form);

  const downloadSummaryReport = async () => {
    setGeneratingSummaryReport(true);
    console.log(
      "company Id",
      companyIds,
      companies?.find((company: any) => company?.id === companyIds)
    );
    const request = {
      companyId: selectedCompanyId ?? companyIds,
      companyName: companies?.find((company: any) => company?.id === companyIds)?.name,
    };

    const response = await generateSummaryReport(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        openReportFromGeneratedFile(data.value);
      }
    }

    setGeneratingSummaryReport(false);
  };
  return (
    <Card title="Water Right Information Export" bodyStyle={{ padding: 12, margin: 0 }}>
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
        {!selectedCompanyId && (
          <CompanyReportSelector
            form={form}
            propertyToSet={"companyIds"}
            label={"Companies"}
            setCompanies={setCompanies}
            placeholder="Select Company"
            style={{ marginTop: 0, marginBottom: 0 }}
            singleSelect
          />
        )}
      </Form>
      <div
        style={{
          margin: "auto",
          width: "50%",
          textAlign: "center",
          marginTop: !selectedCompanyId ? 10 : undefined,
        }}
      >
        {(selectedCompanyId || companyIds?.length > 0) && (
          <Button loading={generatingSummaryReport} disabled={generatingSummaryReport} type="primary" onClick={(e) => downloadSummaryReport()}>
            Download
          </Button>
        )}
      </div>
    </Card>
  );
};

export default ExportWaterRightInformationReport;
