import useWaterRightReportLookupQuery from "@/queries/useWaterRightReportLookupQuery";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { Button, Divider, Form, FormInstance, Select, Space, Switch, Tag } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StatusTag from "../StatusTag/StatusTag";
import WaterRightTagList from "../WaterRightTagList/WaterRightTagList";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";

type WaterRightSelectorProps = {
  placeholder?: string;
  form: FormInstance<any> | undefined;
  style?: React.CSSProperties;
  propertyToSet: string;
  label: string;
  setWaterRights: any;
};

const WaterRightReportSelector: FC<WaterRightSelectorProps> = ({ placeholder, form, style, propertyToSet, label, setWaterRights }) => {
  const [waterRightDownRef, waterRightDownEntry] = useIntersectionObserver();

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [isActive, setIsActive] = useState<boolean | null>(true);

  const { waterRightLookup, invalidateWaterRightLookup, fetchNextPage, hasNextPage, isFetched, isFetching, isFetchingNextPage, isLoading, totalItemCount } = useWaterRightReportLookupQuery({
    isActive: isActive,
  });

  const { companyConfigs } = useComapnyConfigLookup();

  useEffect(() => {
    if (waterRightDownEntry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line
  }, [waterRightDownEntry?.isIntersecting, hasNextPage]);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (waterRightLookup.length > 0) {
      setWaterRights(waterRightLookup);
    }
    // eslint-disable-next-line
  }, [waterRightLookup]);

  const handleReset = () => {
    form!.setFieldValue(propertyToSet, []);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {waterRightLookup?.find((waterRight) => waterRight?.id === value)?.fileNumber}
      </Tag>
    );
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(null);
    else setIsActive(true);
  };

  return (
    <Form.Item label={label} name={propertyToSet} style={style}>
      <Select
        allowClear
        mode={"multiple"}
        loading={isLoading || isFetching}
        tagRender={tagRender}
        placeholder={placeholder}
        showSearch
        filterOption={(input, option) => {
          const searchVal = input?.toLowerCase();
          let list: any[] = [];
          waterRightLookup?.forEach((waterRight) => {
            let match: boolean = false;

            if (waterRight?.fileNumber?.toLowerCase()?.includes(searchVal)) match = true;

            if (match) list.push(waterRight?.id);
          });
          return list.includes(option?.value) ?? [];
        }}
        optionFilterProp="value"
        maxTagCount={3}
        disabled={isLoading}
        options={waterRightLookup?.map((waterRight: any) => {
          return {
            value: waterRight.id,
            label: waterRight.fileNumber,
          };
        })}
        optionRender={(option, { index }) => {
          if (option.value === "loading") return null;
          const waterRight = waterRightLookup?.find((waterRight) => waterRight.id === option?.value);
          if (!waterRight) return null;

          return (
            <div key={waterRight.id}>
              {index === (waterRightLookup?.length ?? 0) - 1 ? <span ref={waterRightDownRef} /> : null}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  // paddingBottom: 5,
                }}
              >
                {waterRight?.fileNumber} <StatusTag status={waterRight?.status} style={{}} />
              </div>
              {!selectedCompanyId && (
                <div style={{ fontWeight: "normal", fontSize: 11, paddingTop: 0, paddingBottom: 5 }}>({companyConfigs?.find((company: any) => company?.id === waterRight?.companyId)?.name})</div>
              )}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  paddingLeft: 0,
                  marginLeft: 0,
                  marginBottom: 0,
                }}
              >
                <WaterRightTagList
                  style={{
                    marginLeft: -2,
                    marginRight: 50,
                    marginTopp: 0,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                  internalTags={waterRight?.internalTags}
                  externalTags={waterRight?.externalTags}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontSize: 12,
                }}
              >
                <b style={{ paddingRight: 4 }}>PDIV:</b> {waterRight?.pdiv ?? "-"}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontSize: 12,
                }}
              >
                <b style={{ paddingRight: 4 }}>CIN:</b> {waterRight?.cin ?? "-"}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontSize: 12,
                }}
              >
                <b style={{ paddingRight: 4 }}>Wells:</b> {waterRight?.wells?.join(", ")}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontSize: 12,
                }}
              >
                <b style={{ paddingRight: 4 }}>Fields:</b> {waterRight?.fields?.join(", ")}
              </div>
              {/* <Divider style={{ margin: 0, padding: 0 }} /> */}
            </div>
          );
        }}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Space style={{ padding: "0 8px 4px" }}>
              {/* <Button
                onClick={() => {
                  form!.setFieldValue(
                    propertyToSet,
                    waterRightLookup?.filter((item: any) => item !== undefined).map((waterRight) => waterRight.id)
                  );
                  setWaterRightNames(
                    waterRightLookup
                      ?.filter((waterRight) => waterRight !== undefined)
                      ?.map((waterRight) => waterRight?.fileNumber)
                      ?.join(", ")
                  );
                }}
              >
                Select All
              </Button> */}
              <Button
                danger
                onClick={() => {
                  handleReset();
                }}
              >
                Clear List
              </Button>
              <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
            </Space>
          </>
        )}
      ></Select>
    </Form.Item>
  );
};

export default WaterRightReportSelector;
