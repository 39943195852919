import { getRestrictionDashboard } from "@/apis/aggregator.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

type RestrictionDashboardList = {
  searchString?: string;
  sortOrder?: any;
  typeFilters?: string[];
};

const useRestrictionDashboardInfiniteQuery = (props: RestrictionDashboardList) => {
  const { profile } = useSelector((state: any) => state.user);

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const { data, isFetching, isFetched, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } = useInfiniteQuery({
    queryKey: [
      CacheKeys.restrictionDashbordInfinite,
      CacheKeys.list,
      {
        profileId: profile?.id,
        sortOrder: props.sortOrder,
        searchString: props.searchString,
        companyId: selectedCompanyId,
        typeFilters: props.typeFilters,
      },
    ],
    queryFn: async ({ pageParam }) => {
      const response = await getRestrictionDashboard({
        ...props,
        page: pageParam,
        companyId: selectedCompanyId,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch restriction dashboard infinite query");
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    gcTime: 30 * 1000, //30 seconds
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.pagination?.hasNextPage) return undefined;
      return lastPageParam + 1;
    },
  });

  const invalidateDashboardRestrictions = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.restrictionDashbordInfinite, CacheKeys.list],
    });
  }, [queryClient]);

  const restrictions = useMemo(() => data?.pages?.map((page) => page.list).flat() ?? [], [data]);
  const currentItemCount = restrictions.length;
  const totalItemCounts = data?.pages?.map((page) => page.pagination?.totalItemCount).flat() ?? [];

  return {
    restrictions,
    isLoading,
    currentItemCount,
    totalItemCount: totalItemCounts[totalItemCounts.length - 1],
    fetchNextPage,
    invalidateDashboardRestrictions,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
    isFetched,
  };
};

export default useRestrictionDashboardInfiniteQuery;
